<template>
<div id="addCompany">
  <div class="addCompany-ct">
    <div class="attestion">
      <h3>编辑营业执照</h3>
      <el-upload class="license-uploader" :on-success="licenseSuccess" :action="baseUrl" :show-file-list="false">
        <img v-if="licenseModel.businessUrl" :src="licenseModel.businessUrl" class="license-pic">
        <div class="license-btn" v-else>
          <img src="@/assets/imgs/company/upload-btn.png" />
          <span>上传营业执照/组织机构代码证</span>
        </div>
      </el-upload>
    </div>
    <div class="publishCompany">
      <el-form :inline="true" :model="licenseModel" ref="ruleForm" label-width="120px">
        <el-form-item label="企业名称">
          <el-input v-model="licenseModel.enterpriseName" placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="法人名称">
          <el-input v-model="licenseModel.corporationName" placeholder="请输入法人名称"></el-input>
        </el-form-item>
        <el-form-item label="注册资本">
          <el-input v-model="licenseModel.registeredCapital" placeholder="请输入注册资本"></el-input>
        </el-form-item>
        <el-form-item label="成立时间">
          <el-date-picker value-format="yyyy年MM月dd日" clearable type="date" placeholder="选择成立时间" v-model="licenseModel.establishedTime">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="社会信用代码">
          <el-input v-model="licenseModel.socialCreditCode" placeholder="请输入社会信用代码"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="licenseModel.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="企业类型" prop="detailAddress">
          <el-select v-model="licenseModel.type" placeholder="请选择企业类型">
            <el-option v-for="item in enterpriseNatureList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="认证状态" prop="detailAddress">
          <el-select v-model="licenseModel.status" :disabled="true" placeholder="请选择认证状态">
            <el-option v-for="item in statusList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经营范围" prop="profile">
          <el-input type="textarea" maxlength="255" v-model="licenseModel.businessScope" placeholder="请输入经营范围"></el-input>
        </el-form-item>
        <div class="saveBtn" @click="save">修改营业执照</div>
      </el-form>
    </div>
  </div>
</div>
</template>

<script>
import { getQualification, getDictData,confirmChanges } from "@/api/index.js";
export default {
  name: "addContacts",
  data() {
    return {
      licenseModel: {
        businessUrl: ''
      },
      baseUrl: '',
      enterpriseNatureList: [],
      statusList: []

    }
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
    this.getQualification()
    this.getDictData('enterprise_nature')
    this.getDictData('business_status')
  },
  methods: {
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'business_status') {
          this.statusList = res.rows
        }
        if (dictType == 'enterprise_nature') {
          this.enterpriseNatureList = res.rows
        }
      })
    },
    getQualification() {
      getQualification().then(res => {
        this.licenseModel = res.data
        console.log(this.licenseModel)
      })
    },
    licenseSuccess(res) {
      this.licenseModel.businessUrl = res.url
    },
    save() {
      confirmChanges(this.licenseModel).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.getQualification()

        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#addCompany {
  width: 100%;
  min-height: 814px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  padding-bottom: 20px;
  padding-top: 20px;
}

.addCompany-ct {
  width: 900px;
  margin: 0 auto;
  min-height: 430px;
  background: #FFFFFF;
  padding: 42px 90px 41px 80px;
  border-radius: 1px 10px 10px 10px;

  .attestion {
    text-align: center;

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }

    p {
      font-size: 16px;
      color: #222222;
      margin-top: 26px;
      margin-bottom: 20px;
    }

    .license-btn {
      width: 362px;
      height: 143px;
      background: #EAF4FF;
      border-radius: 5px;

      img {
        width: 40px;
        height: 40px;
        margin-top: 30px;
        margin-bottom: 13px;
      }

      span {
        display: block;
        font-size: 16px;
        color: #999999;
      }
    }

    .license-pic {
      width: 362px;
      height: 143px;
    }
  }

  .license-uploader {
    margin-top: 25px;
  }

  .publishCompany {
    padding: 0;
    background: none;
    margin-top: 30px;
  }
}

.saveBtn {
  width: 592px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  background: #0077FF;
  border-radius: 5px;
  margin-left: 100px;
  cursor: pointer;
}

.uoloadPic,
.avatar {
  height: 66px;
  width: 66px;
}
</style><style lang="scss">
.publishCompany {
  .el-form-item {
    margin-bottom: 30px;
    vertical-align: bottom
  }

  .el-form-item__label {
    font-size: 16px;
    color: #000000;
  }

  .el-input__inner {
    width: 320px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }

  .el-textarea__inner {
    width: 750px;
    min-height: 120px !important;
    background: #F5F5F5;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #999999;
  }
}

.avator-item {
  width: 400px;
  margin-bottom: 15px !important;

  .el-form-item__label {
    margin-top: 13px;
  }

  .avatar-uploader-icon {
    font-size: 46px;
    color: #ddd;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
}
</style>
